input[type='radio'] + label::before {
  content: '';
  @apply absolute left-0 top-0 w-6 h-6 border-2 rounded-full bg-white;
  border-color: #788296/20%;
}
input[type='checkbox'] + label::before {
  content: '';
  @apply absolute left-0 top-0 w-6 h-6 border-2 rounded-full bg-white;
  border-color: #788296/20%;
}

input[type='radio']:checked + label::after {
  content: '';
  @apply absolute left-1.5 top-1.5 w-3 h-3 rounded-full;
  background-color: #2166eb; /* Inner circle color */
}
input[type='checkbox']:checked + label::after {
  content: '';
  @apply absolute left-1.5 top-1.5 w-3 h-3 rounded-full;
  background-color: #2166eb; /* Inner circle color */
}

/* carousel_custom styles*/
.slick-slide div {
  padding: 0;
  margin: 0;
}

.slick-slide img {
  margin-left: 0;
}
.slick-dots {
  position: absolute;
  top: 47%;
}

.slick-dots ul {
  display: flex;
  width: 3.5rem;
  margin-bottom: 65%;
  margin-left: 75%;
}

.slick-dots li.slick-active div {
  width: 1.5rem !important;
  height: 0.5rem !important;
  background-color: white !important;
}

.slick-dots li div {
  width: 0.5rem;
  height: 0.5rem;
}

@media (min-width: 600px) {
  .slick-dots {
    position: absolute;
    top: 53%;
  }
  .slick-dots ul {
    display: flex;
    width: 40px;
    margin: 0 auto;
  }
}
@media (min-width: 750px) {
  .slick-dots {
    position: absolute;
    top: 60%;
  }
  .slick-dots ul {
    display: flex;
    width: 40px;
    margin: 0 auto;
  }
}

@media (min-width: 1000px) {
  .slick-dots {
    position: absolute;
    top: 65%;
  }
  .slick-dots ul {
    display: flex;
    width: 56px;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  .slick-dots {
    top: 65%;
  }
  .slick-dots ul {
    width: 64px;
    margin: 0 auto;
  }
}
