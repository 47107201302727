body {
  overflow: hidden;
}

.container {
  display: flex;
  width: 300px;
  margin: 30% auto;
  position: relative;
}

.slider-entity {
  flex: 1;
  width: 100px;
  min-height: 500px;
}

.slick-slide {
  opacity: 0.5;
  font-size: 30px;
  text-align: center;
  background: transparent;
  outline: none;
}

.slick-slide {
  transition: opacity 0.3s;
}
.slick-slide div {
  outline: none;
  padding: 5px 0 10px;
  margin: 0 10px 0 0;
  font-size: 1.5rem;
}

.slick-active {
  opacity: 1;
  background: transparent;
}

.mask {
  position: absolute;
  width: 100%;
  height: 60px;
  top: 30%;
  /* margin: 0 15px; */
  pointer-events: none;
  display: flex;
}

.mask .mask-item {
  width: 100px;
  height: 60px;
  text-align: center;
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  position: relative;
  margin: 0 25px;
}

.mask .mask-item span {
  position: absolute;
  bottom: 0;
  left: 45%;
  color: grey;
  font-size: 12px;
}
