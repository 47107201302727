@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pretendard';
  font-weight: 100;
  src: url(./assets/fonts/pretendard/Pretendard-Thin.woff2) format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 200;
  src: url(./assets/fonts/pretendard/Pretendard-ExtraLight.woff2) format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  src: url(./assets/fonts/pretendard/Pretendard-Light.woff2) format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  src: url(./assets/fonts/pretendard/Pretendard-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  src: url(./assets/fonts/pretendard/Pretendard-Medium.woff2) format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  src: url(./assets/fonts/pretendard/Pretendard-SemiBold.woff2) format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  src: url(./assets/fonts/pretendard/Pretendard-Bold.woff2) format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  src: url(./assets/fonts/pretendard/Pretendard-ExtraBold.woff2) format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  src: url(./assets/fonts/pretendard/Pretendard-Black.woff2) format('woff2');
}

body {
  margin: 0;
  font-family:
    'Pretendard',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height: 100vh; */
  /* height: var(--vh); */
}
html,
body,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
:root {
  --vh: 100%;
  --progress-width: 0;
  --buffered-width: 0;
}
.progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  z-index: 4;
  position: relative;
  width: 16px;
  height: 16px;
  background-color: #ecf0f1;
  border: 1px solid #bdc3c7;
  border-radius: 16px;
  cursor: pointer;
}

.progress-bar::before {
  transform: scaleX(var(--progress-width));
  z-index: 3;
}
.progress-bar::after {
  transform: scaleX(var(--buffered-width));
  transform-origin: left;
  z-index: 2;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.slick-list {
  margin-left: 5px;
}
.slick-slide img {
  margin-left: 5px;
}

.dots_custom {
  display: inline-block;
  vertical-align: middle;
  margin: auto 0;
  padding: 0;
}

.dots_custom li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 6px;
  padding: 0;
}

.dots_custom li button {
  border: none;
  background: #434343;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  padding: 0;
}

.dots_custom li.slick-active button {
  background-color: #8592c7;
}

/** ================== */
.hidden-checkbox {
  display: none; /* 기본 체크박스 숨김 처리 */
}

.custom-checkbox-container {
  display: flex;
  align-items: center;
}

.custom-checkbox-label {
  position: relative;
  cursor: pointer;
  padding-left: 35px; /* 체크 마크에 공간을 주기 위해 padding-left 추가 */
  user-select: none;
}

.custom-checkbox-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px; /* 체크 마크의 높이를 20px로 설정 */
  width: 20px; /* 체크 마크의 너비를 20px로 설정 */
  background-color: #eee; /* 배경색 */
  border-radius: 50%; /* 원형으로 만들기 */
  border: 2px solid #ccc; /* 체크되지 않았을 때의 회색 테두리 */
}

/* 체크박스가 체크됐을 때 스타일 */
.custom-checkbox-container input:checked + .custom-checkbox-label .custom-checkbox-mark {
  background-color: #2d344d; /* 배경색을 변경하여 체크된 것을 시각적으로 나타냄 */
  border: 2px solid #ffffff; /* 체크 됐을 때 흰색 테두리 추가 */
}

.custom-checkbox-mark:after {
  /* 체크 모양 제거 */
  display: none; /* 항상 display를 none으로 설정하여 체크 모양이 보이지 않도록 함 */
}
